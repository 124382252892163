import React, { useState, useEffect } from "react";
import { Card, Grid } from "@material-ui/core";
import DataService from "../services/api/data_services";
import Megaview from "../templates/MegaView";
import Layout from "../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import GreyLine from "../components/GreyLine";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrow from "../assets/Icons/go.svg";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import Button from "../components/Button";
import { BASE_SITE_URL } from "../shared/constants";
import PlainCard from "../components/SixPlainCard";
import hoverCardImg1 from "../assets/Images/HowWeDoIt/Synchronised_Development_Operations.svg";
import hoverCardImg2 from "../assets/Images/HowWeDoIt/Completely_Automated_SSDLC.svg";
import hoverCardImg3 from "../assets/Images/HowWeDoIt/Cost_cutting_on_Maintenance_Updates.svg";
import hoverCardImg4 from "../assets/Images/HowWeDoIt/Reduced_Implementation_Time.svg";
import hoverCardImg5 from "../assets/Images/HowWeDoIt/Centralised_repository.svg";
import hoverCardImg6 from "../assets/Images/HowWeDoIt//Proactive_Security_measures.svg";
import MiniImageBannerBgImg2 from "../assets/Images/HowWeDoIt/what-we-do-banner-image.png";

//Movie Slider\
import MobileSmoothSlider from "../components/MobileSmoothSlider";
import clientMovieSliderImage from "../assets/Images/HowWeDoIt/sdfljdsf_2x.png";

//Icon Card List
import IconCardList from "../templates/IconCardList";
import IconCardListIcon1 from "../assets/Images/IconCardList/Creative_Idea.svg";
import IconCardListIcon3 from "../assets/Images/IconCardList/Feedback_Audience.svg";
import IconCardListIcon6 from "../assets/Images/IconCardList/MRR.svg";
import IconCardListIcon4 from "../assets/Images/IconCardList/Presentation.svg";
import IconCardListIcon2 from "../assets/Images/IconCardList/Software_Companies.svg";
import IconCardListIcon5 from "../assets/Images/IconCardList/Work_Flow.svg";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
import MiniImageBannerBgImg12 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
import BannerBgImage from "../assets/Images/MobileApp/desktop_banner_mobile@2x.png";
// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import ClickToViewCard from "../templates/ClickToViewCard";
import ClickToViewCardImg1 from "../assets/Images/HowWeDoIt/Innovation-Consultant.png";
import ClickToViewCardImg2 from "../assets/Images/HowWeDoIt/UI-UX-Consultant.png";
import ClickToViewCardImg3 from "../assets/Images/HowWeDoIt/Software-Engineer.png";
import ClickToViewCardImg4 from "../assets/Images/HowWeDoIt/Quality-Assurance-Engineer.png";
import ClickToViewCardImg5 from "../assets/Images/HowWeDoIt/Ethical-Hacker.png";

//Development
import ReactNative from "../assets/Images/MobileApp/mobilelang_wespeak/react-native.png";
import ReactLogo from "../assets/Images/MobileApp/mobilelang_wespeak/react-js-logo.png";
import Angular from "../assets/Images/MobileApp/mobilelang_wespeak/angular-logo.png";
import Kotlin from "../assets/Images/MobileApp/mobilelang_wespeak/Kotlin-logo.png";
import Android from "../assets/Images/MobileApp/mobilelang_wespeak/Android_new_logo.png";
import Swift from "../assets/Images/MobileApp/mobilelang_wespeak/Swift_logo.png";
import Vue from "../assets/Images/MobileApp/mobilelang_wespeak/Vue.js_Logo.png";
import xamarin from "../assets/Images/MobileApp/mobilelang_wespeak/xamarin-logo.png";
import iOS from "../assets/Images/MobileApp/mobilelang_wespeak/iOS-logo.png";
import Flutter from "../assets/Images/MobileApp/mobilelang_wespeak/flutter-logo.png";
import IonicLogo from "../assets/Images/MobileApp/mobilelang_wespeak/Ionic_Logo.png";
import PhoneGap from "../assets/Images/MobileApp/mobilelang_wespeak/PhoneGap-Stacked-Black-logo.png";
import VisualStudio from "../assets/Images/MobileApp/mobilelang_wespeak/Microsoft Visual Studio.png";
import NextInnovation from "../assets/Images/MobileApp/nextInnovation.png";

import ThreePlainCards from "../templates/ThreePlainCards";

import HoverCardWithBtnIcon1 from "../assets/Images/HowWeDoIt/fixed-cost.svg";
import HoverCardWithBtnIcon2 from "../assets/Images/HowWeDoIt/time-and-material.svg";
import HoverCardWithBtnIcon3 from "../assets/Images/HowWeDoIt/robust-hybrid.svg";
import HoverCardWithBtnIcon4 from "../assets/Icons/CRM.svg";
import HoverCardWithBtnIcon5 from "../assets/Icons/LMS.svg";

//Info View
import Infoview from "../templates/InfoView";

//Miniview
import Miniview from "../templates/MiniView";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";

//Mini Image Banner
//Mini Image Banner 1
import MiniImageBanner1 from "../templates/MiniImageBanner1";

//BlurCardList
import BlurCardList from "../templates/BlurCardList";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";

//Click To View Mega ViewCarousal
import ClickToViewMegaViewCarousal from "../templates/ClickToViewMegaViewCarousal";
import ClickToViewMegaViewCarousalImg1 from "../assets/Images/ClickToViewMegaViewCarousal/Lean_startup_mindset.png";
import ClickToViewMegaViewCarousalImg2 from "../assets/Images/ClickToViewMegaViewCarousal/Contact_and_first_proposal.png";
import ClickToViewMegaViewCarousalImg3 from "../assets/Images/ClickToViewMegaViewCarousal/Product_Workshops_with_the_team.png";
import ClickToViewMegaViewCarousalImg4 from "../assets/Images/ClickToViewMegaViewCarousal/Final_proposal_and_release_plan.png";

//Mobile/Web Application Development
import ogimage from "../assets/Images/ogImages/OG_Mob-App-dev.png";
import crossPlatform from "../assets/Images/MobileApp/Group_6247_2x.png";
import ProgressiveWeb from "../assets/Images/MobileApp/Group_6253_2x.png";
import nativeApp from "../assets/Images/MobileApp/Group_6261_2x.png";
import hybridApp from "../assets/Images/MobileApp/Group_6272_2x.png";

const MobileApplicationDevelopment = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - Mobile Application Development"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - Mobile Application Development&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${limit}&page=${page}&type=Client Story&service_type=PD - Mobile Application Development&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: (
      <>
        Mobile
        <br /> Application <br />
        Development
      </>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        {" "}
        Join the future of mobility <br />{" "}
        <span className="red-line3letter">wit</span>h Digiryte
      </div>
    ),
    width: "82px",
    tag: "h2",
    InfoviewContent: (
      <>
        With our expertise in building robust multi-platform mobile
        applications, we ensure a consistent experience for iOS, Android, or
        Windows devices and platforms. We’ve got the right team to build the
        right solution for your business. We believe in developing a custom
        solution and can help you in building both native and hybrid mobile
        apps, best suited for your business.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg12,
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    tooltip: "yes",
    tip: "TAKE A LOOK",
    route: "/who-we-work-with",
    underline: true,
    underlineClass: "black-line",
  };
  /**
   * Click To View Mega View Carousal
   */

  const ClickToViewMegaViewCarousalData = {
    data: [
      {
        title: "STEP 1: Lean startup approach",
        download: "no",
        bottom: "Collaboration Process",
        displayFIndoutMore: "no",
        card1data: {
          meta_title: "Lean startup approach",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Having a business mind is a skill. No skill is developed by tactics alone. Developing a skill at a very elite level requires behavioural changes, mindset shifts and expanding your personality. And it can take years to develop it. If you blindly follow tactics without the proper mindset, you won’t be executing those tactics the way they ought to be executed.  Let's take an example: Build, Iterate and Measure are the key concepts of Lean startup. There is nothing wrong with this concept. But let’s dig a few layers deeper to really understand what it means. If you directly apply this concept it will not work.",
          mega_view_image: ClickToViewMegaViewCarousalImg1,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "lean-mindset-to-launch-a-successful-software-product",
          slug_type: "article",
        },
      },
      {
        title: "STEP 2: Contact and first proposal",
        card1data: {
          meta_title: "Contact and first proposal",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "After you contact Digiryte, we gather as much information possible about the product idea and spend our time understanding your business needs and identifying your potential customers to ensure we build a platform which will be familiar to your potential users and stands ahead of your competitors. After considering all the factors, we aim to respond in the next 2-3 business days with a pre-proposal which usually consists of a median SDLC ballpark estimate, the bespoke development approach we created based on your requirements and the various steps explaining our working methodology.",
          mega_view_image: ClickToViewMegaViewCarousalImg2,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "digiryte-s-proposal-and-working-methodology",
          slug_type: "article",
        },
      },
      {
        title: "STEP 3: Product workshop and consulting",
        card1data: {
          meta_title: "Product workshop and consulting",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Whether you have an idea for building a mobile app, or you need to build complex internal software in a large corporation, all great digital products should be built from a solid foundation. This means gaining a deep understanding of your business needs, what problem or opportunity you’re trying to solve, an idea of your users behaviours and preferences and the value that the product will add.",
          mega_view_image: ClickToViewMegaViewCarousalImg3,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "4-steps-of-digiryte-s-successful-product-workshop-methodology",
          slug_type: "article",
        },
      },
      {
        title: "STEP 4: Final proposal and release plan",
        card1data: {
          meta_title: "Final proposal and release plan",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "We position ourselves uniquely in the market by taking a higher approach to creating a win-win situation for us and the client to avoid major pitfalls during the development. Having worked closely with the University of Manchester, over time, we have researched and created various methodologies which help our clients and us understand the project workflow better and help us meet expectations, costs and timelines more accurately. Based on the medium (mobile, web or both) and scope of the project, we usually narrow down at least two to three possible methodologies which best suit the flow of the project.",
          mega_view_image: ClickToViewMegaViewCarousalImg4,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "final-proposal-and-release-plan-methodology-of-digiryte",
          slug_type: "article",
        },
      },
    ],
  };

  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };
  /**
   * Miniview
   */

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
    tip: "GET IN TOUCH",
    tooltip: "yes",
  };
  const miniviewDataCaseStudy = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: crossPlatform,
  };
  // Mobile movie slider
  const mobMovieSlider1 = {
    imageUrl: ProgressiveWeb,
  };
  // Mobile movie slider
  const mobMovieSlider2 = {
    imageUrl: nativeApp,
  };
  // Mobile movie slider
  const mobMovieSlider3 = {
    imageUrl: hybridApp,
  };

  /**
   * Click To View Card
   */
  const ClickToViewCardData = [
    {
      title: "Innovation Consultant",
      heading: (
        <>
          <span className="black-line3letter">Inn</span>ovation Consultant
        </>
      ),
      width: "50px",
      content: (
        <div>
          Innovation consultants have a strong business understanding and are
          highly creative thinkers. They can navigate through any industry and
          are highly capable of preparing a roadmap for your product that
          survives fast-paced business and changing consumer demands. They work
          closely with you to understand your vision, how you want business to
          work, and then develop innovative ideas that matter to both your
          customers and the boardroom.
          <br /> <br /> The innovation consultants are overall responsible for
          the success of the project and put in place a unique strategy to test
          the viability of the product on the market. They constantly push
          borders to ensure that your product stands the test of time and stays
          ahead of customers' expectations.
        </div>
      ),
      image: ClickToViewCardImg1,
    },
    {
      title: "Mobile UI/UX Consultant",
      heading: (
        <>
          <span className="black-line3letter">Mob</span>ile UI/UX Consultant
        </>
      ),
      width: "50px",
      content:
        "The user interface is the window through which users understand your brand and experiences your product. A creative and powerful user interface can transform the way consumers engages with your product. Our UI/UX designers understand the depth of human behaviour to create a unique UI that has a high impact on user experience. Our UI/UX team conducts comprehensive and essential research prior to the design and prototyping process, ensuring that we deliver ROI on exceptional experiences. We are your co-creation partners from design to delivery.",
      image: ClickToViewCardImg2,
    },
    {
      title: "Mobile App Developers",
      heading: (
        <>
          <span className="black-line3letter">Mob</span>ile App Developers
        </>
      ),
      width: "50px",
      content:
        "Changes in technology and new areas of specialisation keep our software engineers moving at a fast pace. Our highly talented team of Software Engineers analyses the needs of the client, then designs, tests and develops software to meet those business requirements. We will also carefully analyse the needs of end-users and work closely with you from the early stages of software planning, MVP development, testing, training and support to meet them effectively.",
      image: ClickToViewCardImg3,
    },
    {
      title: "Quality Assurance Engineer",
      heading: (
        <>
          <span className="black-line3letter">Qua</span>lity Assurance Engineer
        </>
      ),
      width: "50px",
      content:
        "Our Quality Assurance specialists not only monitor the quality of the project but also monitor it’s health before it reaches the market. They work closely with the various teams to monitor the quality of the design, test the functionality, and manage the release process. They ensure that the product complies with the industry and government guidelines prior to release.",
      image: ClickToViewCardImg4,
    },
    {
      title: "Ethical Hacker",
      heading: (
        <>
          <span className="black-line3letter">Eth</span>ical Hacker
        </>
      ),
      width: "50px",
      content:
        "Successful testing does not always mean that the system is 100% safe and secure, but it should be able to thwart unskilled hackers and automated attacks. To do this successfully, our certified Ethical Hackers are constantly updated with new security software and hardware and the latest security threats. Our team will then move forward to create unique scripts that simulate network security breaches to test both current and future network additions. These vulnerabilities are then reported and analysed in order to provide a risk assessment to strengthen the defence of the products.",
      image: ClickToViewCardImg5,
    },
  ];
  const InfoviewData1 = {
    InfoviewTitle: (
      <div className="ttc">
        <span className="red-line3letter red-3letter-mbl">Our</span> App
        Development Includes
      </div>
    ),
    tag: "h2",
    width: "100px",
    InfoviewContent: <></>,
  };
  const PlainCardData = [
    {
      HoverCardWithBtntitle: "Synchronised Development & Operations",
      HoverCardWithBtnsubtitle: "(Improves Agility)",
      HoverCardWithBtnIcon: hoverCardImg1,
      HoverCardWithBtnIconAlt: "Synchronised Development & Operations",
      HoverCardIconClass: "plain-card-button-main-img",
    },
    {
      HoverCardWithBtntitle: "Completely Automated SSDLC",
      HoverCardWithBtnIcon: hoverCardImg2,
      HoverCardWithBtnIconAlt: "Completely Automated SSDLC",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Secure Software Development Life-cyle)",
    },
    {
      HoverCardWithBtntitle: "Cost cutting on Maintenance & Updates",
      HoverCardWithBtnIcon: hoverCardImg3,
      HoverCardWithBtnIconAlt: "Cost cutting on Maintenance & Updates",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Cost cutting without Compromising Quality)",
    },
    {
      HoverCardWithBtntitle: "Reduced Implementation Time",
      HoverCardWithBtnIcon: hoverCardImg4,
      HoverCardWithBtnIconAlt: "Reduced Implementation Time",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Months to Minutes)",
    },
    {
      HoverCardWithBtntitle: "Centralised repository",
      HoverCardWithBtnIcon: hoverCardImg5,
      HoverCardWithBtnIconAlt: "Centralised repository",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle:
        "(Version control system for Improved Collobration)",
    },
    {
      HoverCardWithBtntitle: "Proactive Security measures",
      HoverCardWithBtnIcon: hoverCardImg6,
      HoverCardWithBtnIconAlt: "Proactive Security measures",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(From App to Infrastructure)",
    },
  ];
  //Movie Slider
  const movieSlider = {
    imageUrl: clientMovieSliderImage,
  };
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner2Data = {
    title: (
      <span className="whatwedo">
        <span className="black-line3letter">Wha</span>t we do
      </span>
    ),
    titleClass: "mini-image-banner-title-area title-header",
    image: MiniImageBannerBgImg2,
    altTxt: "What we do",
    tip: "TAKE A LOOK",
    tooltip: "yes",
    btnClassName: "what-we-do-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/what-we-do",
    underline: false,
    underlineClass: "black-line",
  };
  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon1,
      altTxt: "Agile Methodology",
      heading: "Agile Methodology",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon2,
      altTxt: "Time-zone collobration",
      heading: "Time-zone collobration",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Tailored product Development",
      heading: "Tailored product Development",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon4,
      altTxt: "Transparent Communication",
      heading: "Transparent Communication",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Direct Onboarding",
      heading: "Direct Onboarding",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon6,
      altTxt: "Constant Testing",
      heading: "Constant Testing",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
  ];
  const ThreeHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle:
      "We'd love to help with your full product development",
    HoverCardWithBtntitle1: "Fixed Cost Model",
    HoverCardWithBtntitle2: "Time & Material Model",
    HoverCardWithBtntitle3: "Robust Hybrid Model",
    HoverCardWithBtntitle4:
      "Customer Relationship Management (CRM) Development",
    HoverCardWithBtntitle5: "Learning Management System (LMS) Development",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContent1:
      "For the Fixed Cost part of the SDLC we will charge you based on the agreed milestones.",
    HoverCardWithBtnContent2:
      "For the T & M part of the SDLC we will charge you based on the cost agreed per hour for each and every resource involved in this Project.",
    HoverCardWithBtnContent3:
      "In this model Define & Design Phase will be in T&M and Development & Testing in Fixed Cost.",
    HoverCardIconClass: "mb-10",
    HoverCardWithBtnContentClass: "btncontent",
    Extracardclassname: "pricing-card-title",
    HoverCardExtraiconclassname: "pricing-card-icon",
    HoverCardWithBtnIconAlt1: "Fixed Cost Model",
    HoverCardWithBtnIconAlt2: "Time & Material Model",
    HoverCardWithBtnIconAlt3: "Robust Hybrid Model",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    route: "/contact-us",
  };
  return (
    <Layout
      seo={{
        title: "Mobile Application Development | Digiryte",
        ogtitle: "Mobile Application Development | Digiryte",
        description:
          "Digiryte specializes in building robust multi-platform mobile applications, ensuring a consistent user experience across iOS, Android, and Windows devices.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/mobile-application-development`,
        keywords:
          "mobile application development, multi-platform apps, iOS, Android, Windows, Digiryte",
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30"></div>
      <Infoview InfoviewData={InfoviewData} />
      <div className="mt-70 body-container ttc">
        <h2 className="h2-header ttc">
          <span className="red-3letter-mbl">The</span> Ryte Team For <br />{" "}
          <span className="red-line3letter">You</span>r Requirements
        </h2>
      </div>
      <div className="mt-30 body-container">
        <ClickToViewCard data={ClickToViewCardData} />
      </div>
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData1} />
      </div>
      <div className="body-container">
        <Grid
          className="mt-50  hide-in-mobile"
          container
          direction="row"
          spacing={3}
        >
          {PlainCardData.map((item, index) => (
            <Grid className="appsgrid" key={index} item md={4}>
              <Card className="appsgridshadow">
                <PlainCard
                  HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                  HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                  HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                  HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                  HoverCardIconClass={item.HoverCardIconClass}
                  HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid className="show-only-in-mobile">
        <MobileSmoothSlider reduceheight="no" movieSlider={movieSlider} />
      </Grid>
      <div className="mt-70">
        <MiniImageBanner1
          id="two"
          MiniImageBanner1Data={MiniImageBanner2Data}
        />
      </div>
      <div className="body-container mt-70">
        <h2 className="ourtraits-title-header ttc">
          <span className="red-line3letter red-3letter-mbl">Our</span> Traits
        </h2>
        <div className="mt-50 mbl-hide">
          <IconCardList IconCardListData={IconCardListData} />
        </div>

        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
      </div>
      <div className="mt-70"></div>
      {/* </span> */}
      <div className="body-container mt-20">
        <h2 className="title-header ttc">
          <span className="red-line3letter red-3letter-mbl">Pri</span>cing
          Models
        </h2>
      </div>
      <div className="mt-50">
        <ThreePlainCards ThreeHoverCardData={ThreeHoverCardData} />
      </div>
      <div className="mt-70 body-container ttc">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Our</span> Collaboration Process
        </h2>
      </div>
      <div className="mt-70"></div>
      <ClickToViewMegaViewCarousal
        ClickToViewMegaViewCarousalData={ClickToViewMegaViewCarousalData}
      />
      <div className="mt-70 body-container">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Mob</span>ile Languages We Speak!
        </h3>
        <div className="mt-50"></div>

        <h3 className="sub-header">Cross platform development</h3>
        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
        <Grid container className="mt-20 mbl-hide" direction="row" spacing={1}>
          <Grid item sm={6} md={3}>
            <img alt="Flutter" src={Flutter} />
            <p style={{ fontSize: "16px" }}>Flutter</p>
          </Grid>
          <Grid item sm={6} md={3}>
            {" "}
            <img alt="xamarin" src={xamarin} />
            <p style={{ fontSize: "16px" }}>Xamarin</p>
          </Grid>
          <Grid item sm={6} md={3}>
            {" "}
            <img alt="ReactNative" src={ReactNative} />
            <p style={{ fontSize: "16px" }}>React Native</p>
          </Grid>
        </Grid>
        <GreyLine />
        <div className="mt-50"></div>
        <h3 className="sub-header">Progressive Web App</h3>
        <MobileMovieSlider movieSlider={mobMovieSlider1} slideid="slide2" />
        <Grid container className="mt-20 mbl-hide" direction="row" spacing={1}>
          <Grid item md={3}>
            <img alt="Angular" src={Angular} />
            <p style={{ fontSize: "16px" }}>Angular</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img alt="ReactLogo" src={ReactLogo} />
            <p style={{ fontSize: "16px" }}>React JS</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img alt="Vue" src={Vue} />
            <p style={{ fontSize: "16px" }}>Vue JS</p>
          </Grid>
        </Grid>
        <GreyLine />
        <div className="mt-50"></div>
        <h3 className="sub-header">Native app development</h3>
        <MobileMovieSlider movieSlider={mobMovieSlider2} slideid="slide3" />
        <Grid container className="mt-20 mbl-hide" direction="row" spacing={1}>
          <Grid item md={3}>
            <img alt="Android" src={Android} />
            <p style={{ fontSize: "16px" }}>Java</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img alt="iOS" src={iOS} />
            <p style={{ fontSize: "16px" }}>IOS</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img alt="Kotlin" src={Kotlin} />
            <p style={{ fontSize: "16px" }}>Kotlin</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img alt="Swift" src={Swift} />
            <p style={{ fontSize: "16px" }}>Swift</p>
          </Grid>
        </Grid>
        <GreyLine />
        <h3 className="sub-header">Hybrid Development</h3>
        <MobileMovieSlider movieSlider={mobMovieSlider3} slideid="slide4" />
        <Grid container className="mt-20 mbl-hide" direction="row" spacing={1}>
          <Grid item md={3}>
            <img alt="IonicLogo" src={IonicLogo} />
            <p style={{ fontSize: "16px" }}>Ionic</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img alt="PhoneGap" src={PhoneGap} />
            <p style={{ fontSize: "16px" }}>Adobe PhoneGap</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img alt="VisualStudio" src={VisualStudio} />
            <p style={{ fontSize: "16px" }}>Microsoft Visual Studio</p>
          </Grid>
        </Grid>
        <GreyLine />
        <div className="mt-70"></div>
        <h2 className="title-header ttc">
          Our Approach To Pick Best Tech <br />
          <span className="red-line3letter">For</span> Your Next Innovation
        </h2>
        <div className="mt-50 body-container"></div>
        <img
          style={{ width: "100%" }}
          src={NextInnovation}
          alt="Digiryte R&D Team"
        />
        <div
          style={{ textAlign: "right", fontSize: "12px", fontStyle: "italic" }}
        >
          Created by Digiryte R&D Team
        </div>
      </div>
      <div className="mt-70">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Cli</span>ent Stories
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              total={clientStoryData.length}
              miniviewData2={miniviewDataCaseStudy}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}

      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          className="mt-60"
          role="presentation"
          onClick={() => {
            setPage(page === 1 ? page + 2 : page + 1);
            setLimit(3);
          }}
          style={{ display: "inline-block" }}
        >
          {clientStoryRawData.has_more && (
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE CLIENT STORIES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="MORE CLIENT STORIES"
            />
          )}
        </div>
      </div>
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-50 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="body-container mt-70">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Oth</span>er Services
        </h3>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default MobileApplicationDevelopment;
